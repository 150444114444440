<template>
  <div>
    <v-subheader>{{ $t("myaccesses") | capitalize }}</v-subheader>

    <v-list subheader style="max-height: 300px" class="overflow-y-auto">
      <v-skeleton-loader
        v-if="!targets"
        class="mx-auto"
        max-width="300"
        type="list-item-avatar"
      ></v-skeleton-loader>

      <v-list-item v-else v-for="target in targets" :key="target.target">
        <v-list-item-icon>
          <v-icon v-if="(target.profile_type == 'MAGASINS')"> mdi-store </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ target.name }} ({{ target.target.replace(':', ' - ') }})
            <v-chip
              class="ml-1"
              color="primary"
              v-for="(bundle, i) in target.bundles"
              :key="i"
            >
              {{ bundle.label }}
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      targets: (state) => state.account.targets,
    }),
  },
};
</script>

